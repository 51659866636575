<template>
  <div>

    <brand-add-new
      :is-add-new-brand-sidebar-active.sync="isAddNewBrandSidebarActive"
      :client-options="clientOptions"
      :user-role="userRole"
      @refetch-data="fetchBrands"
    />

    <brand-edit
      :is-edit-sidebar-active.sync="isEditSidebarActive"
      :client-options="clientOptions"
      :user-role="userRole"
      :edit-brand-id="editBrandId"
      :edit-brand-name="editBrandName"
      :edit-brand-client="editBrandClient"
      @refetch-data="fetchBrands"
    />

    <!-- Filters -->
    <brand-filters
      v-if="userRole != 'client-admin'"
      :client-filter.sync="clientFilter"
      :client-options="clientOptions"
    />

    <b-button
      class="mb-2"
      variant="primary"
      @click="showAddForm"
    >
      <span class="text-nowrap">Add New Brand</span>
    </b-button>

    <b-list-group>
      <b-list-group-item
        v-for="brand in brandsData"
        :key="brand.id"
        class="d-flex justify-content-between align-items-center"
      >
        <div>
          {{ brand.name }}<br>
          <b-badge v-if="userRole != 'client-admin'">
            {{ brand.client_name }}
          </b-badge>
        </div>
        <div>
          <b-button
            variant="primary"
            :to="{ name: 'apps-styles-list', query: { brandFilter: brand.id } }"
          >View Styles</b-button>
          <b-dropdown
            variant="link"
            no-caret
            dropleft
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              @click="editBrand(brand)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item
              :to="{ name: 'apps-styles-list', query: { brandFilter: brand.id } }"
            >
              <feather-icon icon="PackageIcon" />
              <span class="align-middle ml-50">View Styles</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import axios from '@axios'
import {
  BBadge, BButton, BDropdown, BDropdownItem, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import { required } from '@validations'
import {
  onMounted, onUnmounted, ref, watch,
} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'

import BrandAddNew from '@/views/pages/brands/BrandAddNew.vue'
import BrandEdit from '@/views/pages/brands/BrandEdit.vue'
import BrandFilters from '@/views/pages/brands/BrandFilters.vue'

/* eslint-disable global-require */
export default {
  components: {
    BBadge,
    BButton,
    BDropdown,
    BDropdownItem,
    BListGroup,
    BListGroupItem,

    BrandAddNew,
    BrandEdit,
    BrandFilters,
  },
  setup() {
    const isAddNewBrandSidebarActive = ref(false)
    const isEditSidebarActive = ref(false)
    const brandsData = ref([])
    const clientOptions = ref([])
    const name = ''
    const clientFilter = ref(+router.currentRoute.query.clientFilter || null)
    const client = null
    const userRole = JSON.parse(localStorage.getItem('userData')).role
    const editBrandId = ref(0)
    const editBrandName = ref('')
    const editBrandClient = ref(0)
    const STORE_MODULE_NAME = 'brands'

    const brandStore = {
      namespaced: true,
      state: {},
      getters: {},
      mutations: {},
      actions: {
        fetchBrands(ctx, queryParams) {
          return new Promise((resolve, reject) => {
            axios
              .get('/api/v1/brands/', { params: queryParams })
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
        },
        fetchOptions() {
          return new Promise((resolve, reject) => {
            axios
              .get('/api/v1/brands/client_options/')
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
        },
      },
    }

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, brandStore)
    }
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
      }
    })

    const fetchBrands = () => {
      store.dispatch(
        'brands/fetchBrands', { client: clientFilter.value },
      ).then(response => { brandsData.value = response.data })
    }

    const fetchOptions = () => {
      store.dispatch(
        'brands/fetchOptions',
      ).then(response => { clientOptions.value = response.data.clients.options })
    }

    const showAddForm = () => {
      isAddNewBrandSidebarActive.value = true
    }

    const editBrand = brand => {
      isEditSidebarActive.value = true
      editBrandId.value = brand.id
      editBrandName.value = brand.name
      editBrandClient.value = brand.client
    }

    watch([clientFilter], () => {
      fetchBrands()
    })

    onMounted(fetchBrands)
    onMounted(fetchOptions)

    return {
      isAddNewBrandSidebarActive,
      isEditSidebarActive,

      brandsData,
      clientOptions,
      name,
      clientFilter,
      client,
      userRole,
      editBrandId,
      editBrandName,
      editBrandClient,

      fetchBrands,
      showAddForm,
      editBrand,
    }
  },
  data() {
    return {
      required,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
